import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import TopNav from './topNav';



const GiftCards = (props) => {
    return (
        <div>
            <TopNav/>
            GiftCards COMPONENT 
        </div>
    )
}

export default GiftCards;